<template>
  <b-link :href="url" target="_blank" v-if="imageSrc && breakpoints.indexOf(breakpoint) > -1">
    <b-img-lazy
        :blank-width="width"
        :blank-height="height"
        :width="width"
        :height="height"
        fluid
        :src="imageSrc"
    ></b-img-lazy>
  </b-link>
</template>

<script>
import {BImgLazy, BLink} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  name: "AdBanner",
  components: {
    BImgLazy, BLink
  },
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    breakpoints: {
      type: Array,
      default: () => ['xs', 'sm', 'md', 'lg', 'xl']
    }
  },
  data() {
    return {
      imageSrc: null,
      url: null
    }
  },
  computed: {
    banners() {
      return _.get(this.$store.state.ads.ads, this.width + 'x' + this.height, [])
    },
    breakpoint(){
      return this.$store.getters["app/currentBreakPoint"]
    }
  },
  mounted() {
    this.refreshBanner()
    setInterval(() => {
      this.refreshBanner()
    }, 30000)
  },
  methods: {
    refreshBanner() {
      let randomIndex = Math.floor(Math.random() * this.banners.length)
      let banner = _.get(this.banners, randomIndex, null)
      this.imageSrc = _.get(banner, 'image', null)
      this.url = _.get(banner, 'url', null)
    }
  }
}
</script>
